import React, {useState, useEffect} from 'react';
import {Provider} from 'react-redux';
import moment from 'moment'; // Force self-registration
import L from 'leaflet';

import configureStore from './lib/configureStore';
import Root from './app/components/Root';
import {setupAds} from './lib/ads';
import './lib/models';
import Configuration from './lib/configuration';

function App() {
  const [store, setStore] = useState();

  useEffect(() => {
    (async () => {
      const elem = document.getElementById('site_config');
      const site = JSON.parse(elem.textContent);
      Configuration.configure(site);
      L.Icon.Default.imagePath = '/images/leaflet/';
      moment.locale('en');
      const s = configureStore({});
      setStore(s);
      setupAds();
    })();
  }, []);

  if (!store) {
    return null;
  }
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
