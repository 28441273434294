import {isMobileBrowser} from './support/browserDetection';

const Platform = new (class {
  dialPhone(num) {
    if (isMobileBrowser()) {
      window.open(`tel:${num}`, '_top');
      return true;
    }
    return false;
  }

  hasTouchEvents() {
    return 'ontouchstart' in window;
  }

  openExternalUrl(url, target) {
    if (target) {
      window.open(url, target);
    } else {
      window.location.href = url;
    }
  }

  open(...args) {
    return window.open(...args);
  }

  alert(message, {title, buttonName} = {}, callback) {
    const {notification} = window.navigator;
    if (notification) {
      notification.alert(message, callback, title || 'Alert', buttonName);
      return;
    }
    if (typeof window.alert === 'function') {
      window.alert(message);
    } else {
      console.error('window.alert() missing, not alerting');
    }
    if (typeof callback === 'function') {
      callback();
    }
  }

  setViewTitle(title) {
    if (typeof document !== 'undefined') {
      document.title = title;
    }
  }
})();

export default Platform;
