import {applyMiddleware, combineReducers, createStore, compose} from 'redux';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import {browserHistory} from 'react-router';
import {compact} from 'underscore';
import thunkMiddleware from 'redux-thunk';

import * as reducers from './reducers';
import {createAnalyticsMiddleware} from './middlewares';
import {legacyVirtualPageViews} from './analytics/googleAnalytics';

export default function configureStore(initialState) {
  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer,
  });

  let middlewares = [thunkMiddleware];
  middlewares = middlewares.concat(
    compact([createAnalyticsMiddleware(), routerMiddleware(browserHistory)])
  );
  legacyVirtualPageViews(browserHistory);

  let devToolsExtension;
  if (typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') {
    devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
  } else {
    devToolsExtension = (fn) => fn;
  }

  const finalCreateStore = compose(applyMiddleware(...middlewares), devToolsExtension)(createStore);

  return finalCreateStore(rootReducer, initialState);
}
