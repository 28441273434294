import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {omit} from 'underscore';
import Recaptcha from 'react-recaptcha';

import {Spinner} from './Spinner.react';
import TextInput from './common/TextInput';
import Configuration from '../../lib/configuration';
import UpdateOnMount from './UpdateOnMount';
import {analyticsEvent} from '../../lib/actions';

const INITIAL_FORM_STATE = {
  message: '',
  name: '',
  email: '',
  recaptchaResponse: null,
};

export default connect()(
  class ShareListingForm extends React.Component {
    static propTypes = {
      listing: PropTypes.object,
      size: PropTypes.string,
      title: PropTypes.string,
      onReset: PropTypes.func,
      dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
      listing: null,
      size: 'normal',
      title: 'Email this listing',
      onReset: () => null,
    };

    state = Object.assign(
      {
        status: 'not_sent',
        mounted: false,
      },
      INITIAL_FORM_STATE
    );

    render() {
      const recaptchaSiteKey = Configuration.get('services.recaptcha.site_key');
      const nameValid = this.state.name.length > 0;
      const emailValid = /.+@.+\.[a-z][a-z]+$/i.test(this.state.email);
      let content;
      switch (this.state.status) {
        case 'failed':
          content = (
            <div className="share_listing_form_failure">
              <p>
                {"We're sorry, but we were unable to process your request. Please try again later."}
              </p>
              <button onClick={this._handleResetClick}>OK</button>
            </div>
          );
          break;
        case 'sent':
          content = (
            <div className="share_listing_form_success">
              <p>{'Your request was sent. Thank you!'}</p>
              <button onClick={this._handleResetClick}>OK</button>
            </div>
          );
          break;
        case 'sending':
          content = (
            <div className="share_listing_form_success">
              <Spinner />
              <p>{'Submitting…'}</p>
            </div>
          );
          break;
        case 'not_sent':
          content = (
            <form
              method="post"
              action="/api/email/share-listing"
              charSet="utf-8"
              onSubmit={this._handleSubmit}>
              <ul>
                <li>
                  <TextInput
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={this.state.name}
                    onChange={(name) => this.setState({name})}
                    required
                    valid={nameValid}
                  />
                </li>
                <li>
                  <TextInput
                    type="text"
                    name="email"
                    placeholder="Emails (comma separated)"
                    value={this.state.email}
                    onChange={(email) => this.setState({email})}
                    required
                    valid={emailValid}
                  />
                </li>
                <li className="share_listing_form_message">
                  <TextInput
                    type="textarea"
                    name="message"
                    placeholder="Message"
                    value={this.state.message}
                    onChange={(message) => this.setState({message})}
                    valid={this.state.message.trim().length > 0}
                  />
                </li>
                <UpdateOnMount>
                  <li>
                    {recaptchaSiteKey && (
                      <Recaptcha
                        elementID="share-captcha"
                        sitekey={recaptchaSiteKey}
                        verifyCallback={(recaptchaResponse) => this.setState({recaptchaResponse})}
                        expiredCallback={() => this.setState({recaptchaResponse: null})}
                      />
                    )}
                  </li>
                </UpdateOnMount>
              </ul>
              <div className="share_listing_form_bottom">
                {this.state.status === 'sending' ? (
                  <span className="share_listing_form_bottom_progress">Submitting…</span>
                ) : (
                  <span className="share_listing_form_bottom_button">
                    <input
                      type="submit"
                      value="Send"
                      disabled={!(emailValid && nameValid && this.state.recaptchaResponse)}
                    />
                  </span>
                )}
              </div>
            </form>
          );
      }

      return (
        <div className="share_listing_form" data-size={this.props.size}>
          <h3>{this.props.title}</h3>
          {content}
        </div>
      );
    }

    _handleResetClick = (event) => {
      event.preventDefault();
      this.setState({status: 'not_sent'});
      this.props.onReset();
    };

    _handleSubmit = async (event) => {
      event.preventDefault();

      if ((this.state.name || '').trim().length === 0) {
        alert('Please fill in your name.');
        return;
      }
      if ((this.state.email || '').trim().length === 0) {
        alert("Please fill in the recipient's email.");
        return;
      }

      this.setState({status: 'sending'});

      try {
        const response = await fetch('/api/email/share-listing', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-recaptcha-response': this.state.recaptchaResponse,
          },
          body: JSON.stringify(
            Object.assign(
              {
                test_mode: !!Configuration.get('test_mode'),
                id: this.props.listing && this.props.listing.id,
                slug: this.props.listing && this.props.listing.slugify(),
                // None of these are used by the new backend.
                // listing_url: urlForListing(this.props.listing),
                // external_url: this.props.listing.details_page_url,
              },
              omit(this.state, 'status', 'recaptchaResponse', 'mounted')
            )
          ),
        });

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const {listing} = this.props;
        const agent = listing.agent;
        const broker = listing.broker;
        this.props.dispatch(
          analyticsEvent('sharedListingByEmail', {
            listing,
            agent,
            broker,
            requesterName: this.state.name,
            targetEmails: this.state.email,
          })
        );
        this.setState(
          Object.assign(
            {
              status: 'sent',
            },
            INITIAL_FORM_STATE
          )
        );
      } catch (err) {
        console.error(err);
        this.setState({status: 'failed'});
      }
    };
  }
);
